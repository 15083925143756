<template>
    <b-row>
        <b-col cols="12">
            <zw-select-group v-model="inputVal.filter"
                             :options="filters"
                             name="filter"
                             :label-prefix="labelPrefix"
                             validate="required"
            ></zw-select-group>
        </b-col>
        <b-col cols="12">
            <zw-select-group v-model="inputVal.sftp_profile"
                             :options="getSftpProfiles()"
                             name="sftp_profile"
                             :label-prefix="labelPrefix"
            ></zw-select-group>
        </b-col>
    </b-row>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'ExportOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
            filters: []
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getEmailTemplates']),
        ...mapGetters('Export', ['getSftpProfiles']),
    },
    mounted() {
        window.axios.get(window.apiUrl + '/workflow/table-filters', {'skip_loading': true})
            .then(response => {
                this.filters = response.data.table_filters
            })
            .finally(() => {
                this.loading = false
            })
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>